<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ventanas de Enturnamiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Ventanas de Enturnamiento</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-ventana_enturnamiento-export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('hidrocarburos.ventanasEnturnamiento.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Clase de Viaje
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.clase_viaje"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="clase_viaje in listasForms.clases_viaje"
                            :key="clase_viaje.numeracion"
                            :value="clase_viaje.numeracion"
                          >
                            {{ clase_viaje.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ventanas.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.clase_viaje }}</td>
                      <td>
                        <span
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                          @click="llenarModal(item.id, item.estado)"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.ventanasEnturnamiento.show'
                              )
                            "
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.ventanasEnturnamiento.delete'
                              )
                            "
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ventanas.total">
                  <p>
                    Mostrando del <b>{{ ventanas.from }}</b> al
                    <b>{{ ventanas.to }}</b> de un total:
                    <b>{{ ventanas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ventanas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <VentanasEnturnamientoEstado ref="VentanasEnturnamientoEstado" />
      </div>
    </div>
        <VentanasEnturnamientoExport ref="VentanasEnturnamientoExport" />
  </div>
</template>

<script>
import axios from "axios";
import VentanasEnturnamientoEstado from "./VentanasEnturnamientoEstado";
import pagination from "laravel-vue-pagination";
import VentanasEnturnamientoExport from "./VentanasEnturnamientoExport";

export default {
  name: "VentanasEnturnamiento",
  components: {
    pagination,
    VentanasEnturnamientoEstado,
    VentanasEnturnamientoExport,
  },
  data() {
    return {
      filtros: {
        nombre: null,
        clase_viaje: null,
      },
      ventanas: {},
      listasForms: {
        estados: [],
        clases_viaje: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/hidrocarburos/ventanasEnturnamiento?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ventanas = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getClasesViaje() {
      axios.get("/api/lista/18").then((response) => {
        this.listasForms.clases_viaje = response.data;
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("hidrocarburos.ventanasEnturnamiento.show")) {
        this.$refs.VentanasEnturnamientoEstado.llenar_modal(id, estado);
      }
    },

    destroy(idVentanaEnturne) {
      this.$swal({
        title: "Esta seguro de eliminar esta Ventana de enturnamiento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/ventanasEnturnamiento/" + idVentanaEnturne)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino la Ventana de enturnamiento exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
    create() {
      return this.$router.push({
        name: "/Hidrocarburos/VentanasEnturnamientoForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(ventana) {
      return this.$router.push({
        name: "/Hidrocarburos/VentanasEnturnamientoForm",
        params: { accion: "Editar", data_edit: ventana },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getClasesViaje();
    this.getMsg();
  },
};
</script>
